/* VIDEO */
/* video 1 */
#videoMrg {
  /* margin-bottom: 100px; */
  margin-bottom: 40px;
}
/* Video 2 */
#target {
  /* margin-bottom: 330px; */
  margin-bottom: 40px;
}
/*  */
video {
  margin-top: 20px;
  margin-bottom: -10px;
  border:3px solid red;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  z-index: 10000;
  /* position: absolute; */
}

#video {
  width: 900px;
  /* position: relative; */
}

video#video::-webkit-media-controls,
video#video::-webkit-media-controls-start-playback-button,
video#video::-webkit-media-controls-play-button,
video#video::-webkit-media-controls-panel,
video#video::-webkit-media-controls-container,
video#video::-webkit-media-controls-overlay-play-button,
video#video::-webkit-media-controls-fullscreen-button,
video#video::-webkit-media-controls-timeline,
video#video::-webkit-media-controls-current-time-display,
video#video::-webkit-media-controls-mute-button,
video#video::-webkit-media-controls-toggle-closed-captions-button,
video#video::-webkit-media-controls-volume-slider,
video#video::-webkit-media-controls-enclosure {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}

/*  */
video::-webkit-media-controls-fullscreen-button {
  display: none;
}

video::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}
video::-webkit-media-controls-timeline {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}
video::-webkit-media-controls-current-time-display {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}
video::-webkit-media-controls-time-remaining-display {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}

video::-webkit-media-controls-mute-button {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}
video::-webkit-media-controls-volume-slider {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}

/* Remove botão de download de vídeos */
video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

/* video::-webkit-media-controls-panel {
    width: calc(100% + 30px); djust as needed 
} */
/*  */
.progressBar {
  width: 900px;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  color: #ffb400;
  margin: 0 auto;
  opacity: 1;
  /*  */
  border: 1px solid red;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  /* É necessário alterar o valor para none */
  -webkit-appearance: none;
  outline: none;
}
/* Altera a cor de fundo em navegadores com webkit (Chrome, Safari etc) */
::-webkit-progress-value {
  /* background-color: orange; */
  background-color: #ffb400;
  opacity: 0.9;
}
/* Altera a cor da barra em navegadores com webkit (Chrome, Safari etc) */
::-webkit-progress-bar {
  /* background-color: green; */
  /* background-color: rgb(35, 35, 212, 0.9); */
  background-color: aliceblue;
  opacity: 0.9;
}
/* Altera a cor da barra em navegadores com moz (Firefox) */
::-moz-progress-bar {
  background-color: #fff;
}

/* Altera a cor da barra em navegadores da Microsoft (IE e Edge) */
::-ms-fill {
  background-color: #fff;
}

.ouvirVideo {
  /*  */
  margin-left: 400px;
  margin-top: -350px;
  /* margin-top: -1050px; */
  margin-bottom: 130px;
  position: absolute;
  opacity: 0.9;
  /* z-index: 10000; */
}

.ouvirVideo button {
  color: #fff;
  font-weight: bold;
  /*  */
  margin: 0 auto;
}

.ouvirVideo2 {
  margin-top: -300px;
  position: relative;
  /* position: relative; */
  opacity: 0.9;
}

#btnInicio {
  /* background: #ffb400; */
  background: #ff0000;
  width: 290px;
  height: 200px;
  margin: 0 auto;
  border-radius: 6px;
  /* border: 1px solid #1e1e1e; */
  border: 1px solid #ff0000;
  padding: 6px;
  outline: none;
  /* opacity: 0.9; */
}

#btnInicio2 {
  /* background: #ffb400; */
  background: #ff0000;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #ff0000;
  padding: 16px;
  position: relative;
}

.duration {
  margin-top: -45px;
  margin-bottom: 40px;
  /* float: left; */
  margin-left: 600px;
  /*  */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffb400;
}

#h6Texto {
  /* margin-top: 40px; */
  color: #fff;
  font-weight: bold;
}

/* Bo compr gor */
.a-btn {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  border: none;
  outline: none;
  text-align: center;
  /*  */
  /* position: relative !important; */
  /* display: inline-block !important; */
  /*  */
  /* width: 100%;  */
  width: 300px;
  /* margin-bottom: 10px;  */
  padding: 6px;
  /* border-color: #ff5535; 
          border-width: 0px;  */
  border-radius: 3px;
  background: #04AF24;
  /* box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.5);  */
  text-decoration: none !important;
  /*  */
  /* margin-bottom: -3660px; */
  /* margin-top: 30px; */
  /* margin-bottom: -30px; */
}

.span-btn {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: block;
  position: relative;
  z-index: 1;
  padding: 0 15px;
  white-space: normal;
  font-size: 1.1rem;
  color: #ffffff;
  font-family: Oswald;
  font-weight: bold;
  text-shadow: #000000 0px 1px 0px;
  text-decoration: none;
}

/* MEDIA */
@media (max-width: 1280px) {
  /* Video 2 */
  #target {
    /* margin-bottom: 320px; */
    margin-bottom: 40px;
  }
}

@media (max-width: 1024px) {
  .ouvirVideo {
    margin-left: 310px;
    margin-top: -350px;
    margin-bottom: 130px;
  }
  /* Video 2 */
  #target {
    /* margin-bottom: 320px; */
    margin-bottom: 40px;
  }
}

@media (max-width: 912px) {
  #video {
    width: 100%;
  }
  .progressBar {
    width: 100%;
  }
  .ouvirVideo {
    margin-left: 190px;
    margin-top: -300px;
    margin-bottom: 130px;
  }
  /* Video 2 */
  #target {
    /* margin-bottom: 320px; */
    margin-bottom: 12px;
  }
}

@media (max-width: 820px) {
  #video {
    width: 100%;
  }
  .progressBar {
    width: 100%;
  }
  .ouvirVideo {
    margin-left: 190px;
    margin-top: -300px;
    margin-bottom: 130px;
  }
  /* Video 2 */
  #target {
    /* margin-bottom: 320px; */
    margin-bottom: 40px;
  }
}

@media (max-width: 800px) {
  #video {
    width: 100%;
  }
  .progressBar {
    width: 100%;
  }
  .ouvirVideo {
    margin-left: 190px;
    margin-top: -300px;
    margin-bottom: 130px;
  }
  /*  */
  .ouvirVideo2 {
    margin-top: -250px;
  }
  /* Video 2 */
  #target {
    /* margin-bottom: 290px; */
    margin-bottom: 40px;
  }
}

@media (max-width: 712px) {
  #video {
    width: 100%;
  }
  .progressBar {
    width: 100%;
  }
  .ouvirVideo {
    margin-left: 160px;
    margin-top: -200px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 200px;
    height: 90px;
    font-size: 17px;
    font-weight: bold;
  }
  /*  */
  .ouvirVideo2 {
    margin-top: -190px;
  }
  /* Video 2 */
  #target {
    /* margin-bottom: 240px; */
    margin-bottom: 40px;
  }
}

@media (max-width: 600px) {
  #video {
    width: 100%;
  }
  .progressBar {
    width: 100%;
  }
  .ouvirVideo {
    margin-left: 150px;
    margin-top: -200px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 190px;
    height: 90px;
  }
  /*  */
  .ouvirVideo2 {
    margin-top: -190px;
  }
  /* Video 2 */
  #target {
    /* margin-bottom: 220px; */
    margin-bottom: 40px;
  }
}

@media (max-width: 480px) {
  #esseTitulo {
    font-size: 1.3rem;
  }
  .ouvirVideo {
    margin-left: 140px;
    margin-top: -180px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 200px;
    height: 90px;
  }
}

@media (max-width: 414px) {
  #esseTitulo {
    font-size: 0.9rem;
  }
  .ouvirVideo {
    margin-left: 100px;
    margin-top: -170px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 190px;
    height: 90px;
  }
}

@media (max-width: 412px) {
  #esseTitulo {
    font-size: 0.9rem;
  }
  .ouvirVideo {
    margin-left: 100px;
    margin-top: -170px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 190px;
    height: 90px;
  }
  .texte {
    margin-bottom: -100px;
  }
  .a-btn {
    margin-bottom: 90px;
  }
}

@media (max-width: 393px) {
  .a-btn {
    margin-bottom: 90px;
  }
}

@media (max-width: 390px) {
  #esseTitulo {
    font-size: 0.9rem;
  }
  .ouvirVideo {
    margin-left: 85px;
    margin-top: -160px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 190px;
    height: 90px;
  }
  .a-btn {
    margin-bottom: 100px;
  }
}

@media (max-width: 384px) {
  #esseTitulo {
    font-size: 0.9rem;
  }
  .ouvirVideo {
    margin-left: 80px;
    margin-top: -160px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 190px;
    height: 90px;
  }
  /*  */
  .ouvirVideo2 {
    margin-top: -139px;
  }
  /* Video 2 */
  #target {
    /* margin-bottom: 160px; */
    margin-bottom: 40px;
  }
}

@media (max-width: 375px) {
  .texte {
    margin-bottom: -120px;
  }
  .ouvirVideo {
    margin-top: -170px;
    margin-left: 60px;
  }
  .a-btn {
    margin-bottom: 100px;
  }
  #btnInicio {
    width: 240px;
    height: 110px;
  }
  #target:hover {
    display: block;
    cursor: pointer;
  }
  /*  */
  video#video::-webkit-media-controls,
  video#video::-webkit-media-controls-start-playback-button,
  video#video::-webkit-media-controls-play-button,
  video#video::-webkit-media-controls-panel,
  video#video::-webkit-media-controls-container,
  video#video::-webkit-media-controls-overlay-play-button,
  video#video::-webkit-media-controls-fullscreen-button,
  video#video::-webkit-media-controls-timeline,
  video#video::-webkit-media-controls-current-time-display,
  video#video::-webkit-media-controls-mute-button,
  video#video::-webkit-media-controls-toggle-closed-captions-button,
  video#video::-webkit-media-controls-volume-slider,
  video#video::-webkit-media-controls-enclosure {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0 !important;
  }

  /*  */
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  video::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0 !important;
  }
  video::-webkit-media-controls-timeline {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0 !important;
  }
  video::-webkit-media-controls-current-time-display {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0 !important;
  }
  video::-webkit-media-controls-time-remaining-display {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0 !important;
  }

  video::-webkit-media-controls-mute-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0 !important;
  }
  video::-webkit-media-controls-toggle-closed-captions-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0 !important;
  }
  video::-webkit-media-controls-volume-slider {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0 !important;
  }

  /* Remove botão de download de vídeos */
  video::-internal-media-controls-download-button {
    display: none;
  }

  video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }
}

@media (max-width: 360px) {
  #esseTitulo {
    font-size: 0.9rem;
  }
  #video {
    width: 100%;
  }
  .progressBar {
    width: 100%;
  }
  .ouvirVideo {
    margin-left: 70px;
    margin-top: -150px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 190px;
    height: 90px;
  }
  /*  */
  .ouvirVideo2 {
    margin-top: -139px;
  }
  /* Video 2 */
  /* .a-btn{
    margin-bottom: -108px;
  } */

  #target {
    /* margin-bottom: 180px; */
    margin-bottom: 40px;
  }
  #h6Texto {
    margin-top: 5px;
  }
  .texte {
    margin-bottom: -120px;
  }
}

@media (max-width: 320px) {
  .ouvirVideo {
    margin-left: 50px;
    margin-top: -140px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 200px;
    height: 90px;
  }
  .a-btn {
    margin-bottom: 100px;
  }
}

@media (max-width: 280px) {
  #esseTitulo {
    font-size: 1rem;
  }
  .ouvirVideo {
    margin-left: 44px;
    margin-top: -130px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 170px;
    /* height: 90px; */
  }
  /*  */
  .a-btn {
    margin-bottom: 100px;
  }
}

@media (max-width: 240px) {
  #esseTitulo {
    font-size: 1rem;
  }
  .ouvirVideo {
    margin-left: 22px;
    margin-top: -120px;
    margin-bottom: 130px;
  }
  #btnInicio {
    width: 170px;
    /* height: 90px; */
  }
  /* // */
  .a-btn {
    margin-bottom: 100px;
  }
  /*  */
  .ouvirVideo2 {
    margin-top: -100px;
  }
  /* Video 2 */
  #target {
    /* margin-bottom: 130px; */
    margin-bottom: 40px;
  }
}
