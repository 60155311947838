body{
  /* font-family: 'Times New Roman', Times, serif; */
  background-color: blueviolet;
}

::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, #FFB400);
    border-radius: 12px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#FFB400, #FFB400);
  }
  
  @media (max-width: 576px) {
    ::-webkit-scrollbar {
      width: 4px;
    }
  
    ::-webkit-scrollbar-thumb {
      background: linear-gradient(transparent, #FFB400);
      border-radius: 6px;
    }
  a
    ::-webkit-scrollbar-thumb:hover {
      background: linear-gradient(#FFB400, #FFB400);
    }
  }
  