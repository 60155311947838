@import "../src/styles/assets/style.css";
@import "../src/styles/home.css";
@import "../src/styles/botao.css";
@import "../src/styles/video.css";
/* @import "../src/styles/likes.css"; */

.App {
  text-align: center;
}

