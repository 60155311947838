* {
  /* font-family: 'Times New Roman', Times, serif; */
  padding: 0;
  margin: 0;
}

header {
  width: 100%;
  color: #fff;
  background: #2f0258;
  /* background: #365899; */
  padding: 6px;
}


/*  */
.fb-comments {
  background-color: #fff;
  border: 1px solid #e9ebee;
  border-radius: 3px;
  padding: 0 15px;
  padding-bottom: 15px;
  margin: auto;
  position: relative;
  color: #4267b2;
}

.fb-comments-header {
  float: left;
  /* padding: 15px 0; */
  border-bottom: 1px solid #e9ebee;
}

.fb-comments-header span {
  color: #000;
  font-weight: 700;
  font-size: 0.9em;
}

.fb-comments-comment {
  border: none;
  padding: 0;
  margin: 10px 0;
  width: 100%;
}

.fb-comments-reply-wrapper {
  margin-left: 60px;
  border-left: 1px dotted #e9ebee;
  padding-left: 5px;
}

tr,
td {
  border: none;
  margin: 0;
}

td {
  padding: 2.5px;
}

tr {
  padding: 2.5px 0;
}

.fb-comments-comment-img {
  vertical-align: top;
  width: 48px;
  padding-right: 5px;
}

.fb-comments-comment-img img {
  max-width: 48px;
  border-radius: 25px;
}

.fb-comments-comment-name {
  float: left;
  font-size: 0.85em;
}

.fb-comments-comment-name name {
  color: #365899;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  cursor: hand;
}

.fb-comments-comment-name name:hover {
  text-decoration: underline;
}

.fb-comments-comment-name occupation {
  color: #90949c;
}

.fb-comments-comment-text {
  font-size: 0.9em;
  color: #000;
  border-radius: 21px;
  background-color: #eaebef;
  padding: 10px 20px;
  text-align: center;
}

.fb-comments-comment-actions like,
.fb-comments-comment-actions reply {
  float: left;
  font-size: 0.75em;
  color: #4267b2;
  text-decoration: none;
  cursor: pointer;
  cursor: hand;
  margin: 6px;
}

.fb-comments-comment-actions like.liked {
  color: #90949c;
}

.fb-comments-comment-actions like:hover,
.fb-comments-comment-actions reply:hover {
  text-decoration: underline;
}

.fb-comments-comment-actions likes {
  font-size: 13px;
  background-repeat: no-repeat;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 3px;
  padding-bottom: 2px;
  margin-top: -10px;
  float: right;
  background-color: #fff;
  border: solid #eaebef;
  border-radius: 19px;
}

.fb-comments-comment-actions date {
  float: left;
  font-size: 0.75em;
  color: #90949c;
  text-decoration: none;
  cursor: pointer;
  cursor: hand;
  margin: 6px;
}

.fb-comments-comment-actions date:hover {
  text-decoration: underline;
}

.fb-comments-loadmore {
  background: #4080ff;
  border: 1px solid #4080ff;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  padding: 0.875em;
  text-shadow: none;
  width: 100%;
  font-weight: 700;
  cursor: hand;
  cursor: pointer;
}

.fb-reply-input {
  border: 1px solid lightgrey;
  border-radius: 3px;
  width: 100%;
  padding: 5px 7.5px;
  font-size: 0.75em;
  color: #000;
  outline: none;
}

.fb-reply-input:hover,
.fb-reply-button:hover {
  outline: none;
}

.fb-reply-button {
  background: #4080ff;
  border: 1px solid #4080ff;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  font-size: 0.75em;
  padding: 5px 7.5px;
  text-shadow: none;
  width: 100%;
  font-weight: 700;
  cursor: hand;
  cursor: pointer;
  outline: none;
}

.bbtn {
  width: 100%;
  max-width: 449px;
}

/* Bo compr gor */
.a-btn {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  border: none;
  outline: none;
  text-align: center;
  /*  */
  position: relative !important;
  display: inline-block !important;
  /*  */
  /* width: 100%;  */
  width: 300px;
  /* margin-bottom: 10px;  */
  padding: 6px;
  /* border-color: #ff5535; 
        border-width: 0px;  */
  border-radius: 3px;
  /* background: linear-gradient(to bottom, #ff6600 0%, #ff5535 100%);  */
  background: #ffb400;
  /* box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.5);  */
  text-decoration: none !important;
  /*  */
  /* margin-bottom: -3660px; */
  margin-top: 30px;
  margin-bottom: -30px;
}

.span-btn {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: block;
  position: relative;
  z-index: 1;
  padding: 0 15px;
  white-space: normal;
  font-size: 1.1rem;
  color: #ffffff;
  font-family: Oswald;
  font-weight: bold;
  text-shadow: #000000 0px 1px 0px;
  text-decoration: none;
}

/* MEDIA */
@media (max-width: 800px) {
 
}

@media (max-width: 768px) {

  .comments {
    margin-top: 20px;
  }
}


/*  */
@media (max-width: 600px) {
  .comments {
    margin-top: 20px;
  }
}
/*  */

@media (max-width: 414px) {
  .tituloPV {
    font-size: 1.1rem;
  }
  .span-btn {
    text-shadow: none;
  }
}

@media (max-width: 390px) {
}

@media (max-width: 375px) {

  .span-btn {
    text-shadow: none;
  }
  .comments {
    margin-top: 0px;
  }
}

@media (max-width: 360px) {

}

@media (max-width: 280px) {
  .a-btn {
    width: 200px;
    padding: 0px;
  }
  .span-btn {
    text-shadow: none;
  }

}
