#bgFundo{
    /* background-color: blueviolet; */
    background: url('../img/bg.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
.parabens{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.parabens-card{
    background: #fff;
    border-radius: 10px;
    padding: 60px;
}

.parabens-card h1{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 40px;
}

.parabens-card span{
    /* text-transform: uppercase; */
    font-weight: 900;
    font-size: 0.9rem;
}

.parabens-card #btnLink{
    margin-top: 30px;
}
.parabens-card a{
    text-decoration: none;
    background: #09e226;
    padding: 10px;
    border-radius: 9px;
    color: #fff;
    width: 100%;
}

.parabens-card a:hover{
    color: #fff;
}
